import { cloneDeep } from "lodash";

import type { DocumentNode, QueryHookOptions } from "@apollo/client";

import { AuthenticatedClientConfig } from "../../../common/config/config";

import { STR_ID } from "./constants";

import getLogger from "$common/log";
import { NavConfig } from "$common/nav-config";
import { client } from "$graphql/client/client-side-provider";
import { HAMBURGER_MENU_QUERY } from "$graphql/core";
import { HAMBURGER_MENU_NEW_QUERY } from "$graphql/core/queries/hamburger-menu";

export type PostData = {
    ApplicationId: number;
    Culture: string;
    MarketingBrandId: number;
};

/**
 * Stub type for the bff API response. This is a placeholder for
 * the `CoStar.Api.SiteNavigation.ViewModels.Menu` type exposed by
 * `@costar-gen/pds-node-client`
 */
export interface SiteNavigationMenu {
    MenuId: number;
    MarketingBrandId: number;
    ApplicationId: number;
    ViewName: string;
    IsActivated: boolean;
    MenuItems: unknown[]; // MenuItem[]
    MenuAttributes: unknown[]; // MenuAttribute[]
    MenuAttributesDisplay: unknown[]; // MenuAttributeDisplay[]
    MenuItemGroups?: unknown[]; // MenuGroupAttribute[]
    NotActivated?: string[]; // List of ids - not exactly product ids, but similar
}

export type SiteNavigationMenuResponse = {
    data: SiteNavigationMenu;
};

const queryPromise = (query: DocumentNode, options: QueryHookOptions) => {
    return client.query({
        query,
        variables: options.variables,
    });
};

const completeHamburgerMenu = (menuItems): SiteNavigationMenu => {
    return {
        MenuId: 41,
        MarketingBrandId: 1,
        ApplicationId: 2,
        ViewName: "GenericMenu_2_enUS_Id",
        IsActivated: true,
        MenuItems: menuItems,
        MenuAttributes: [
            {
                MenuAttributeId: 267,
                MenuId: 41,
                MenuAttributeName: "hasSignOutInfo",
                MenuAttributeValueType: null,
                MenuAttributeValue: "True",
                __typename: "MenuAttribute",
            },
        ],
        MenuAttributesDisplay: [
            {
                MenuAttributeDisplayId: 26,
                MenuId: 9,
                Culture: "en-US",
                DisplayAttributeName: "SignOutLabel",
                DisplayAttributeValue: "Sign Out",
                __typename: "MenuAttributeDisplay",
            },
            {
                MenuAttributeDisplayId: 0,
                MenuId: 0,
                Culture: null,
                DisplayAttributeName: "FirstName",
                DisplayAttributeValue: "Taha",
                __typename: "MenuAttributeDisplay",
            },
            {
                MenuAttributeDisplayId: 0,
                MenuId: 0,
                Culture: null,
                DisplayAttributeName: "LastName",
                DisplayAttributeValue: "Iftekhar",
                __typename: "MenuAttributeDisplay",
            },
            {
                MenuAttributeDisplayId: 0,
                MenuId: 0,
                Culture: null,
                DisplayAttributeName: "UserPhoto",
                DisplayAttributeValue: null,
                __typename: "MenuAttributeDisplay",
            },
        ],
    };
};

export const makeFetchMenuCallback = (config: AuthenticatedClientConfig, navConfig: NavConfig) =>
    // async function fetchMenuCallback(postData: PostData): Promise<SiteNavigationMenuResponse> {
    async function fetchMenuCallback(postData: PostData): Promise<SiteNavigationMenu> {
        const logger = getLogger();

        const response = await queryPromise(HAMBURGER_MENU_QUERY, {
            variables: {
                ApplicationId: postData.ApplicationId,
                Culture: postData.Culture,
                MarketingBrandId: postData.MarketingBrandId,
            },
        });

        if (response.error) {
            const error = new Error(
                `Failed to get hamburger menu data: ${response.error.name} ${response.error.message}`
            );
            Object.assign(error, { status: response.error.name, message: response.error.message });
            logger.error(`Error in fetching hamburger menu data. Message: ${response.error.message}`);
            logger.error(response.error.message);
            throw error;
        }

        if (response && response?.data?.user?.hamburgerMenu) {
            // Cloning here because UniversalMenu adds keys to this object. This object is non-extensible so keys cannot be added
            // The only solution is to not use the object and use its clone instead. This is probably because of React.StrictMode
            const menu = cloneDeep(response?.data?.user?.hamburgerMenu);
            const transformed = transformFetchMenuResponse(menu, config, navConfig);
            return transformed;
        } else {
            // TODO: decide on better fail behavior
            return {} as SiteNavigationMenu;
        }
    };

export const makeFetchNewMenuCallback = (config: AuthenticatedClientConfig, navConfig: NavConfig) =>
    async function fetchMenuCallback(postData: PostData): Promise<SiteNavigationMenu> {
        const logger = getLogger();

        const response = await queryPromise(HAMBURGER_MENU_NEW_QUERY, {
            variables: {
                Culture: postData.Culture,
            },
        });

        if (response.error) {
            const error = new Error(
                `Failed to get new hamburger menu data: ${response.error.name} ${response.error.message}`
            );
            Object.assign(error, { status: response.error.name, message: response.error.message });
            logger.error(`Error in fetching hamburger menu data. Message: ${response.error.message}`);
            logger.error(response.error.message);
            throw error;
        }

        if (response && response?.data?.user?.hamburgerMenuNew?.hamburgerMenu) {
            const menu = completeHamburgerMenu(response.data.user.hamburgerMenuNew.hamburgerMenu);
            // Cloning here because UniversalMenu adds keys to this object. This object is non-extensible so keys cannot be added
            // The only solution is to not use the object and use its clone instead. This is probably because of React.StrictMode
            const menuClone = cloneDeep(menu);
            return menuClone;
        } else {
            return {} as SiteNavigationMenu;
        }
    };

const transformFetchMenuResponse = (
    res: SiteNavigationMenu,
    _config: AuthenticatedClientConfig,
    navConfig: NavConfig
): SiteNavigationMenu => {
    // TODO: modify tenant tab to support new tenant based on feature flag

    if (res?.NotActivated?.includes(STR_ID)) {
        const strConfig = navConfig.find(tabConfig => tabConfig.id === STR_ID);
        if (strConfig?.isUserSubscriber) {
            res.NotActivated = res.NotActivated.filter(menuId => menuId !== STR_ID);
        }
    }

    return res;
};
