import { useMemo } from "react";

import { propertyTypes } from "@costar/costar-enums";
import type Logger from "@costar/logger";
import type { OptionsFetcher } from "@costar/theme-ui-autocomplete";
import { CoStar } from "@costar-gen/data-services-pds/types";

import { getThreeLetterCountry, ThreeLetterCountryCode } from "../../../../common";
import { LookupSearchMatchType, SearchItemOption } from "../types";

import getLogger from "$common/log";
import type { SearchItem } from "$graphql/core";
import { SearchType as SearchTypeGQL } from "$graphql/core";
import type { Resources } from "$i18n";

export const useOptionsText = (resources: Resources) => {
    const logger = getLogger();
    const getLabel = useMemo(() => makeGetLabel(resources, logger), [resources, logger]);
    const getSecondary = useMemo(() => makeGetSecondary(resources, logger), [resources, logger]);

    return { getLabel, getSecondary };
};

export const makeGetLabel =
    (resources: Resources, _logger: Logger) =>
    (item: SearchItem, term: string, searchType: SearchTypeGQL): string => {
        if (item.line1) {
            return item.line1;
        }
        if (searchType === SearchTypeGQL.PublicRecord) return `${item?.name} ${item?.country}`;

        let label: string = item.item?.DisplayText.trim().replace(/\s+/g, " ") ?? "";

        if (term === item.id && item?.item?.Elements?.EntityType === "SaleCompID") {
            label += resources?.text.saleCompId();
        }

        if (item.country) {
            const threeLetterCode = getThreeLetterCountry(item.country);
            let countryDisplay: ThreeLetterCountryCode;
            switch (getThreeLetterCountry(item.country)) {
                case "USA":
                    countryDisplay = resources.text.country.USA() as ThreeLetterCountryCode;
                    break;
                case "CAN":
                    countryDisplay = resources.text.country.CAN() as ThreeLetterCountryCode;
                    break;
                case "GBR":
                    countryDisplay = resources.text.country.GBR() as ThreeLetterCountryCode;
                    break;
                default:
                    countryDisplay = threeLetterCode;
            }
            label += ` ${countryDisplay}`;
        }
        if (
            term &&
            term.trim() === item.item?.Elements?.PropertyId &&
            item.item.Elements?.EntityType === "PropertyAddressOther"
        ) {
            label += ` (${resources?.text.propertyId({ propertyId: item.item?.Elements?.PropertyId })})`;
        }
        return label;
    };

const makeGetSecondary =
    (resources: Resources, logger: Logger) =>
    (item: SearchItem, searchType: SearchTypeGQL, isGeocodeEnabled?: boolean): string => {
        if (item.line2) {
            return item.line2;
        }
        const {
            typeAhead: { matchType: matchResources },
        } = resources;

        // const { propertyType, matchType } = item as {
        //     propertyType: propertyTypes | string | null | undefined;
        //     matchType: LookupSearchMatchType | string;
        // };
        const { propertyType, matchType, country } = item;

        if (
            isGeocodeEnabled &&
            matchType === CoStar.Api.Lookup.MatchType.GeocodeResult &&
            (searchType === SearchTypeGQL.AllProperties ||
                searchType === SearchTypeGQL.ForSale ||
                searchType === SearchTypeGQL.SaleComps)
        ) {
            return resources.typeAhead.geocode.secondaryLine();
        }

        if (searchType === SearchTypeGQL.PublicRecord || searchType === SearchTypeGQL.Ownerscloud) {
            return `${item?.item?.TypeAheadResult?.Description}`;
        }

        if (isNaN(matchType) && isNaN(Number(propertyType))) {
            return "";
        }
        const propertyTypeAsNum = Number(propertyType);

        switch (Number(matchType)) {
            case LookupSearchMatchType.Property: {
                const { label, propertyType: pt } = matchResources.property;
                switch (propertyTypeAsNum) {
                    case propertyTypes.hospitality:
                        return pt.hospitality();
                    case propertyTypes.industrial:
                        return pt.industrial();
                    case propertyTypes.land:
                        return pt.land();
                    case propertyTypes.office:
                        return pt.office();
                    case propertyTypes.generalRetail:
                        return pt.retail();
                    case propertyTypes.flex:
                        return pt.flex();
                    case propertyTypes.sportsAndEntertainment:
                        return pt.sports();
                    case propertyTypes.specialty:
                        return pt.speciality();
                    case propertyTypes.multifamily:
                        return pt.multiFamily();
                    case propertyTypes.student:
                        return pt.student();
                    case propertyTypes.healthCare:
                        return pt.healthCare();
                    default:
                        return label();
                }
            }

            case LookupSearchMatchType.PostalCode:
                return matchResources.zipCode();

            case LookupSearchMatchType.PostalCodeGlobal:
                return matchResources.postalCodeGlobal();

            case LookupSearchMatchType.County:
                return matchResources.county();

            case LookupSearchMatchType.Subdivision:
                return getCountryBasedDescription(matchResources, country);

            case LookupSearchMatchType.PostalCodeGroup:
                return matchResources.postalGroupCode();

            case LookupSearchMatchType.BuildingPark:
                return matchResources.buildingPark();

            case LookupSearchMatchType.ShoppingCenter:
                return matchResources.shoppingCenter();

            case LookupSearchMatchType.Country:
                return matchResources.country();

            case LookupSearchMatchType.Region:
                return matchResources.region();

            case LookupSearchMatchType.City:
                return matchResources.city();

            case LookupSearchMatchType.Metro:
                return matchResources.metro();

            case LookupSearchMatchType.MSA:
                return matchResources.metro35();

            case LookupSearchMatchType.BuildingName:
                return matchResources.building();

            case LookupSearchMatchType.Street:
                return matchResources.street();

            case LookupSearchMatchType.Contact:
                return matchResources.contact8();

            case LookupSearchMatchType.ContactSaleComp:
                return matchResources.contact81();

            case LookupSearchMatchType.ContactProperty:
                return matchResources.contact82();

            case LookupSearchMatchType.ContactLocation:
                return matchResources.location();

            case LookupSearchMatchType.ContactCompany:
                return matchResources.company9();

            case LookupSearchMatchType.ContactLeaseDeal:
                return matchResources.contact83();

            case LookupSearchMatchType.ContactTenant:
                return matchResources.contact84();

            case LookupSearchMatchType.Company:
                return matchResources.company9();

            case LookupSearchMatchType.Fund:
                return matchResources.fund();

            case LookupSearchMatchType.FundOwner:
                return matchResources.fundManager();

            case LookupSearchMatchType.ResearchMarket:
                return matchResources.metroMarket();

            case LookupSearchMatchType.Comp:
            case LookupSearchMatchType.SaleCompID:
                return matchResources.saleComp();

            case LookupSearchMatchType.CompanySaleComp:
                return matchResources.saleComp91();

            case LookupSearchMatchType.Submarket: {
                // Property type cultural resources
                const { label, propertyType: pt } = matchResources.submarket;
                switch (propertyTypeAsNum) {
                    case propertyTypes.hospitality:
                        return pt.hospitality();
                    case propertyTypes.industrial:
                        return pt.industrial();
                    case propertyTypes.land:
                        return pt.land();
                    case propertyTypes.office:
                        return pt.office();
                    case propertyTypes.generalRetail:
                        return pt.retail();
                    case propertyTypes.flex:
                        return pt.flex();
                    case propertyTypes.sportsAndEntertainment:
                        return pt.sports();
                    case propertyTypes.specialty:
                        return pt.speciality();
                    case propertyTypes.multifamily:
                        return pt.multiFamily();
                    case propertyTypes.student:
                        return pt.student();
                    case propertyTypes.healthCare:
                        return pt.healthCare();
                    default:
                        return label();
                }
            }

            case LookupSearchMatchType.CoStarMarket: {
                const res = resources.typeAhead.matchType.costarMarket;
                switch (propertyTypeAsNum) {
                    case propertyTypes.hospitality:
                        return res.propertyType.hospitality();
                    default:
                        return res.label();
                }
            }

            case LookupSearchMatchType.Tenant:
                return matchResources.tenantLocation();

            default:
                logger.warn(`Unknown match/property type; match type: ${matchType}, propertyType: ${propertyType}`);
                return "";
        }
    };

// TODO(don): Make this a generic + memoized hook and move to src/client/hooks
function debounce(
    func: (value: string) => Promise<Array<SearchItemOption>>,
    interval: number
): OptionsFetcher<SearchItemOption> {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        return new Promise(resolve => {
            timer = setTimeout(() => resolve(func(...args)), interval);
        });
    };
}

const getCountryBasedDescription = (resources, country: string | null | undefined) => {
    switch (country) {
        case "GB":
        case "GBR":
            return resources.subDivision.gbr();
        case "CA":
        case "CAN":
            return resources.subDivision.can();
        case "FR":
        case "FRA":
            return resources.subDivision.fra();
        case "ES":
        case "ESP":
            return resources.subDivision.esp();
        case "DEU":
        case "DE":
            return resources.subDivision.deu();
        case "US":
        case "USA":
            return resources.subDivision.usa();
        default:
            return resources.subDivision.unknown();
    }
};
