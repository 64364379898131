import type { Request } from "express";

import { LandingPaths, MainProductIds, ProductId, SectionId, SectionProductIds } from "./index";

import getLogger from "$common/log";
import { SuiteSearchType } from "$graphql/core";

type TabSelectionType = {
    mainTab: ProductId;
    subTab?: SectionId | undefined;
    isRegex?: boolean;
};

export const isProductId = (id: unknown): id is ProductId => typeof id === "string" && id in MainProductIds;
export const isSectionId = (id: unknown): id is SectionId => typeof id === "string" && id in SectionProductIds;

const mapToTabs = (product: string): TabSelectionType => {
    if (product == null || isNaN(+product)) {
        return { mainTab: MainProductIds.HOME };
    }
    switch (+product) {
        case SuiteSearchType.None:
            return { mainTab: MainProductIds.HOME };
        case SuiteSearchType.Property:
            return { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.ALL_PROPERTIES };
        case SuiteSearchType.ForLease:
            return { mainTab: MainProductIds.LEASING, subTab: SectionProductIds.FOR_LEASE };
        case SuiteSearchType.ForSale:
            return { mainTab: MainProductIds.SALES, subTab: SectionProductIds.FOR_SALE };
        case SuiteSearchType.SaleComps:
            return { mainTab: MainProductIds.SALES, subTab: SectionProductIds.SALE_COMPS };
        case SuiteSearchType.LeaseComps:
            return { mainTab: MainProductIds.LEASING, subTab: SectionProductIds.LEASE_COMPS };
        case SuiteSearchType.PublicRecord:
            return { mainTab: MainProductIds.PUBLIC_RECORD };
        case SuiteSearchType.Tenants:
            return { mainTab: MainProductIds.TENANT, subTab: SectionProductIds.MODERN_TENANTS_COMPANIES };
        case SuiteSearchType.Professionals:
            return { mainTab: MainProductIds.PROFESSIONALS, subTab: SectionProductIds.PROFESSIONALS_ALL_CONTACTS };
        default:
            return { mainTab: MainProductIds.HOME };
    }
};

export const getTabSelectionFromRequest = (req: Request): TabSelectionType => {
    // Check if active-product-id is explicity specified in initial attributes
    // within the request query parameters.
    const selectionFromQueryAttributes = getTabSelectionFromQuery(req.query);
    if (selectionFromQueryAttributes) {
        const { mainTab, subTab } = selectionFromQueryAttributes;
        getLogger().debug({
            message: `Found active product from query attributes: (${mainTab}, ${subTab})`,
            ...selectionFromQueryAttributes,
        });

        return selectionFromQueryAttributes;
    } else {
        return getTabSelectionFromUrl(req.headers.referer);
    }
};
/** Decides which tabs in the main and sub nav to select, based on the url.*/
export const getTabSelectionFromUrl = (url: string | undefined): TabSelectionType => {
    let mainTab: ProductId = MainProductIds.NONE;
    let subTab: SectionId | undefined = undefined;

    if (!url) {
        return { mainTab, subTab };
    }

    if (url.search(LandingPaths.PRODUCT_MATCH_SEARCH) >= 0) {
        const arr = LandingPaths.PRODUCT_MATCH_SEARCH.exec(url);
        LandingPaths.PRODUCT_MATCH_SEARCH.lastIndex = 0; // This reset regex pointer to 0 so next time the regex is scanned from the beginning
        return mapToTabs(arr?.[2] ?? "");
    } else if (url.search(LandingPaths.LENDER_APP) >= 0) {
        mainTab = MainProductIds.LENDER;
    } else {
        const urlWithoutBaseUrl = removeBaseUrlFromUrl(url);

        navigationMaps.forEach((val, key) => {
            if (val.isRegex !== true) {
                if (urlWithoutBaseUrl.startsWith(key as string)) {
                    mainTab = val.mainTab;
                    subTab = val.subTab;
                }
            } else if (url.search(key) > 0) {
                mainTab = val.mainTab;
                subTab = val.subTab;
            }
        });
    }

    if (url.search("detail") > 0) {
        // if we're in the detail page, de-select the subTab so the sub-nav will not show up on the detail page.
        subTab = undefined;
    }
    return { mainTab, subTab };
};
// TODO: this should be part of the unified nav config object.
const navigationMaps = new Map<string | RegExp, TabSelectionType>([
    // Home URL
    [LandingPaths.HOME, { mainTab: MainProductIds.HOME }],

    // Match URLs (Typeahead search)
    [LandingPaths.PROPERTIES_MATCH_SEARCH2, { mainTab: MainProductIds.PROPERTIES }],
    [
        LandingPaths.PRODUCT_MATCH_SEARCH,
        { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.ALL_PROPERTIES, isRegex: true },
    ],

    [LandingPaths.PUBLIC_RECORD, { mainTab: MainProductIds.PUBLIC_RECORD }],
    [LandingPaths.PROPERTIES, { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.ALL_PROPERTIES }],
    [LandingPaths.PROPERTIES_DETAIL, { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.ALL_PROPERTIES }],
    [LandingPaths.MULTI_FAMILY, { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.MULTI_FAMILY }],
    [LandingPaths.MULTI_FAMILY_DETAIL, { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.MULTI_FAMILY }],
    [LandingPaths.SALE_COMPS, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.SALE_COMPS }],
    [LandingPaths.SALE_COMPS_DETAIL, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.SALE_COMPS }],
    [LandingPaths.FOR_SALE, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.FOR_SALE }],
    [LandingPaths.FOR_SALE_DETAIL, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.FOR_SALE }],
    [LandingPaths.AUCTIONS, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.AUCTIONS }],
    [LandingPaths.AUCTIONS_DETAIL, { mainTab: MainProductIds.SALES, subTab: SectionProductIds.AUCTIONS }],
    [LandingPaths.OWNERS_FUNDS, { mainTab: MainProductIds.OWNER_COMPANIES, subTab: SectionProductIds.OWNERS_FUNDS }],
    [LandingPaths.OWNERS_FUNDS_CLOUD, { mainTab: MainProductIds.CLOUD_FUNDS }],
    [LandingPaths.NEW_LEASE_COMPS, { mainTab: MainProductIds.LEASING, subTab: SectionProductIds.NEW_LEASE_COMPS }],
    [LandingPaths.MANAGE_COMPS, { mainTab: MainProductIds.LEASING, subTab: SectionProductIds.MANAGE_COMPS }],
    [
        LandingPaths.OWNERS_COMPANIES_CLOUD,
        { mainTab: MainProductIds.OWNER_COMPANIES, subTab: SectionProductIds.CLOUD_OWNERS_COMPANIES },
    ],
    [
        LandingPaths.MODERN_TENANTS_COMPANIES,
        { mainTab: MainProductIds.TENANT, subTab: SectionProductIds.MODERN_TENANTS_COMPANIES },
    ],
    [
        LandingPaths.MODERN_TENANTS_LOCATIONS,
        { mainTab: MainProductIds.TENANT, subTab: SectionProductIds.MODERN_TENANTS_LOCATIONS },
    ],
    [
        LandingPaths.PROFESSIONALS,
        { mainTab: MainProductIds.PROFESSIONALS, subTab: SectionProductIds.PROFESSIONALS_ALL_CONTACTS },
    ],
    [
        LandingPaths.PROFESSIONALS_ALL_CONTACTS,
        { mainTab: MainProductIds.PROFESSIONALS, subTab: SectionProductIds.PROFESSIONALS_ALL_CONTACTS },
    ],
    [
        LandingPaths.PROFESSIONALS_ALL_LOCATIONS,
        { mainTab: MainProductIds.PROFESSIONALS, subTab: SectionProductIds.PROFESSIONALS_ALL_LOCATIONS },
    ],
    [
        LandingPaths.PROFESSIONALS_ALL_COMPANIES,
        { mainTab: MainProductIds.PROFESSIONALS, subTab: SectionProductIds.PROFESSIONALS_ALL_COMPANIES },
    ],
    [LandingPaths.MARKET, { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_AND_SUBMARKETS }],
    [LandingPaths.MARKET_INSIGHTS, { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_INSIGHTS }],
    [
        LandingPaths.MARKET_SAVED_SEARCHES,
        { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_SAVED_SEARCHES },
    ],
    [LandingPaths.STR, { mainTab: MainProductIds.STR }],
    [LandingPaths.LENDER, { mainTab: MainProductIds.LENDER }],
    [LandingPaths.WEB_ENTERPRISE_CASES, { mainTab: MainProductIds.WEB_ENTERPRISE }],
    [LandingPaths.WEB_ENTERPRISE_REVIEWS, { mainTab: MainProductIds.WEB_ENTERPRISE }],
    [LandingPaths.WEB_ENTERPRISE_INTERACTIONS, { mainTab: MainProductIds.WEB_ENTERPRISE }],
    [LandingPaths.WEB_ENTERPRISE_REFERRALS, { mainTab: MainProductIds.WEB_ENTERPRISE }],

    [LandingPaths.MARKET, { mainTab: MainProductIds.MARKETS }],
    [LandingPaths.MARKET2, { mainTab: MainProductIds.MARKETS }],
    [LandingPaths.MARKET_INSIGHTS, { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_INSIGHTS }],
    [LandingPaths.DATA_EXPORT, { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.DATA_EXPORT }],
    [LandingPaths.LISTINGS, { mainTab: MainProductIds.LISTINGS, subTab: SectionProductIds.LISTINGS_FOR_LEASE }],
    [
        LandingPaths.LISTINGS_FOR_LEASE,
        { mainTab: MainProductIds.LISTINGS, subTab: SectionProductIds.LISTINGS_FOR_LEASE },
    ],
    [LandingPaths.LISTINGS_FOR_SALE, { mainTab: MainProductIds.LISTINGS, subTab: SectionProductIds.LISTINGS_FOR_SALE }],
    [LandingPaths.MODERN_PROPERTIES, { mainTab: MainProductIds.PROPERTIES, subTab: SectionProductIds.ALL_PROPERTIES }],
]);

function getTabSelectionFromQuery(query: Request["query"]): TabSelectionType | undefined {
    const activeProductKey = query["active-product-key"];
    if (!activeProductKey || Array.isArray(activeProductKey)) return;

    // FIXME(don): This is a hack to get Markets up and running. We gotta
    // refactor.... basically all of this
    switch (activeProductKey) {
        case "MARKETS":
            return { mainTab: MainProductIds.MARKETS }; // market mfe mode
        case "MARKET_AND_SUBMARKETS":
            return { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_AND_SUBMARKETS };
        case "DATA_EXPORT":
            return { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.DATA_EXPORT };
        case "MARKET_INSIGHTS":
            return { mainTab: MainProductIds.MARKETS, subTab: SectionProductIds.MARKET_INSIGHTS };
        case "STR":
            return { mainTab: MainProductIds.STR }; // STR uses their own subnav
        default:
            return undefined;
    }
}

const removeBaseUrlFromUrl = (url: string): string => {
    if (isValidUrl(url)) {
        const urlParts = url.split("/");
        return urlParts.slice(3).join("/");
    } else if (url.startsWith("/")) {
        return url.slice(1);
    }
    return url;
};

const isValidUrl = (url: string) => {
    try {
        return Boolean(new URL(url));
    } catch (e) {
        return false;
    }
};
