import { UpsellProductKey } from "./types";

export default {
    PROPERTIES: "properties",
    ALL_PROPERTIES: "properties",
    MULTI_FAMILY: "multiFamily",
    SHOPPING_CENTERS: "shoppingCenters",
    SHOPPING_CENTERS_USA: "shoppingCenters",
    SHOPPING_CENTERS_CAN: "shoppingCenters",
    SHOPPING_CENTERS_GBR: "shoppingCenters",
    PROPERTY_UNDERWRITING_REPORTS: "properties",
    PUBLIC_RECORD: "publicRecord",
    DEAL_MAKERS_USA: "dealMakers",
    DEAL_MAKERS_CAN: "dealMakers",
    DEAL_MAKERS_GBR: "dealMakers",
    LEASING: "leasing",
    FOR_LEASE: "forLease",
    LEASE_COMPS: "leaseComps",
    LEASE_COMPS_US: "leaseComps",
    SEARCHLEASECOMPSUSA: "leaseComps",
    LEASE_COMPS_CA: "leaseComps",
    SEARCHLEASECOMPSCAN: "leaseComps",
    LEASE_COMPS_GB: "leaseComps",
    SEARCHLEASECOMPSGBR: "leaseComps",
    ADD_A_LEASE_COMP_USA: "leaseComps",
    ADD_A_LEASE_COMP_CAN: "leaseComps",
    ADD_A_LEASE_COMP_GBR: "leaseComps",
    IMPORT_LEASE_COMPS_USA: "leaseComps",
    IMPORT_LEASE_COMPS_CAN: "leaseComps",
    IMPORT_LEASE_COMPS_GBR: "leaseComps",
    LEASE_ANALYSIS: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_US: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_MODELS_USA: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_CA: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_MODELS_CAN: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_GB: "leaseAnalysis",
    VIEW_LEASE_ANALYSIS_MODELS_GBR: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_US: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_MODELS_USA: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_CA: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_MODELS_CAN: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_GB: "leaseAnalysis",
    CREATE_LEASE_ANALYSIS_MODELS_GBR: "leaseAnalysis",
    SALES: "sales",
    SALE_COMPS: "saleComps",
    FOR_SALE: "forSale",
    AUCTIONS: "auctions",
    TENANT: "tenants",
    TENANTS: "tenants",
    TENANTS_US: "tenants",
    TENANTS_USA: "tenants",
    TENANTS_CA: "tenants",
    TENANTS_CAN: "tenants",
    TENANTS_GB: "tenants",
    TENANTS_GBR: "tenants",
    PROFESSIONALS: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONAL_US: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONALS_USA: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONAL_CA: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONALS_CAN: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONAL_GB: "realEstateProfessionals",
    REAL_ESTATE_PROFESSIONALS_GBR: "realEstateProfessionals",
    MARKETS: "analytics",
    MARKET_REPORT: "analytics",
    BROWSE_MARKET: "analytics",
    CUSTOM_ANALYTIC_SEARCH: "analytics",
    MARKET_AND_SUBMARKETS: "analytics",
    MARKET_INSIGHTS: "analytics",
    MARKET_EXPORT: "analytics",
    DATA_EXPORT: "analytics",
    MARKETING_CENTER: "analytics",
    STR: "str",
    FUNDS: "funds",
    OWNER_COMPANIES: "owner_companies",
    OWNERCOMPANIES: "owner_companies",
    LISTINGS: "listings",
} as Record<string, UpsellProductKey | undefined>;

export const getLearnMoreUrl = (messageKey?: string) => {
    switch (messageKey) {
        case "leaseAnalysis":
            return "https://www.costar.com/products/costar-lease-analysis";
        case "publicRecord":
            return "https://www.costar.com/products/costarpublicrecord";
        case "saleComps":
            return "https://www.costar.com/costar-comps";
        case "leaseComps":
            return "https://www.costar.com/products/costar-lease-comps";
        case "tenants":
            return "https://www.costar.com/products/tenant-data";
        case "analytics":
            return "https://www.costar.com/products/analytics";
        case "str":
            return "https://www.costar.com/products/str-benchmarking";
        default:
            return "https://www.costar.com/overview";
    }
};

export const getDemoUrl = (isUK: boolean, messageKey?: string) => {
    if (messageKey === "str") return "https://www.costar.com/home/str-benchmarking-demo";
    else if (!isUK) return "https://www.costar.com/home/demo";
    else return "https://www.costar.co.uk/home/demo";
};
