import gql from "graphql-tag";

export const HAMBURGER_MENU_QUERY = gql`
    query HamburgerMenuData($ApplicationId: Int, $Culture: String, $MarketingBrandId: Int) {
        user {
            hamburgerMenu(ApplicationId: $ApplicationId, Culture: $Culture, MarketingBrandId: $MarketingBrandId) {
                MenuId
                MarketingBrandId
                ApplicationId
                ViewName
                IsActivated
                MenuItems {
                    MenuItemId
                    MenuItemName
                    MenuItemTypeId
                    MenuItemTypeName
                    MenuItemUrl
                    MenuItemParentId
                    LevelId
                    DisplayOrder
                    GroupName
                    IsActivated
                    CheckUserAttribute
                    CheckEntitlement
                    ChildItems {
                        MenuItemId
                        MenuItemName
                        MenuItemTypeId
                        MenuItemTypeName
                        MenuItemUrl
                        MenuItemParentId
                        LevelId
                        DisplayOrder
                        GroupName
                        IsActivated
                        CheckUserAttribute
                        CheckEntitlement
                        ChildItems {
                            MenuItemId
                            MenuItemName
                            MenuItemTypeId
                            MenuItemTypeName
                            MenuItemUrl
                            MenuItemParentId
                            LevelId
                            DisplayOrder
                            GroupName
                            IsActivated
                            CheckUserAttribute
                            CheckEntitlement
                            MenuItemDisplayName
                            MenuItemDisplayNames {
                                MenuItemDisplayAttributeId
                                MenuItemId
                                Culture
                                DisplayAttributeName
                                DisplayAttributeValue
                            }
                            MenuItemEntitlements {
                                MenuItemEntitlementMappingId
                                MenuItemId
                                EntitlementTypeId
                                EntitlementTypeName
                                ValueType
                                Value
                            }
                            MenuItemUserAttributes
                            MenuItemAttributes {
                                MenuItemAttributeId
                                MenuItemId
                                MenuItemAttributeName
                                MenuItemAttributeValue
                            }
                            MenuItemGroupAttributes
                        }
                        MenuItemDisplayName
                        MenuItemDisplayNames {
                            MenuItemDisplayAttributeId
                            MenuItemId
                            Culture
                            DisplayAttributeName
                            DisplayAttributeValue
                        }
                        MenuItemEntitlements {
                            MenuItemEntitlementMappingId
                            MenuItemId
                            EntitlementTypeId
                            EntitlementTypeName
                            ValueType
                            Value
                        }
                        MenuItemUserAttributes
                        MenuItemAttributes {
                            MenuItemAttributeId
                            MenuItemId
                            MenuItemAttributeName
                            MenuItemAttributeValue
                        }
                        MenuItemGroupAttributes
                    }
                    MenuItemDisplayName
                    MenuItemDisplayNames {
                        MenuItemDisplayAttributeId
                        MenuItemId
                        Culture
                        DisplayAttributeName
                        DisplayAttributeValue
                    }
                    MenuItemEntitlements {
                        MenuItemEntitlementMappingId
                        MenuItemId
                        EntitlementTypeId
                        EntitlementTypeName
                        ValueType
                        Value
                    }
                    MenuItemUserAttributes
                    MenuItemAttributes {
                        MenuItemAttributeId
                        MenuItemId
                        MenuItemAttributeName
                        MenuItemAttributeValue
                    }
                    MenuItemGroupAttributes
                }
                MenuAttributes {
                    MenuAttributeId
                    MenuId
                    MenuAttributeName
                    MenuAttributeValueType
                    MenuAttributeValue
                }
                MenuAttributesDisplay {
                    MenuAttributeDisplayId
                    MenuId
                    Culture
                    DisplayAttributeName
                    DisplayAttributeValue
                }
                MenuItemGroups
                NotActivated
            }
        }
    }
`;

export const HAMBURGER_MENU_NEW_QUERY = gql`
    query HamburgerMenuNewData($Culture: String) {
        user {
            hamburgerMenuNew(Culture: $Culture) {
                hamburgerMenu {
                    GroupName
                    MenuItemName
                    IsActivated
                    MenuItemDisplayName
                    MenuItemTypeId
                    MenuItemUrl
                    NewTab
                    MenuItemAttributes {
                        MenuItemAttributeName
                        MenuItemAttributeValue
                    }
                    ChildItems {
                        MenuItemName
                        MenuItemDisplayName
                        MenuItemTypeId
                        MenuItemUrl
                        MenuItemAttributes {
                            MenuItemAttributeName
                            MenuItemAttributeValue
                        }
                    }
                }
            }
        }
    }
`;
