import React from "react";

import { Flex, List, ListItem, Spinner, Text } from "@costar/theme-ui";

import { RichIcu } from "$i18n/react-i18n";

export type SufficiencyCheckerTooltipProps = {
    primaryMessage: string;
    secondaryMessage?: string;
    rulesBrokenMessages?: string[];
    loadingResults: boolean;
    loadingMessage: string;
};

export const SufficiencyCheckerTooltip = (props: SufficiencyCheckerTooltipProps) => {
    const { primaryMessage = "", secondaryMessage = "", rulesBrokenMessages, loadingResults, loadingMessage } = props;
    const spinnerSX = { svg: { fill: "none" } };

    return (
        <Flex flexDirection="column" gap="lg">
            <Flex flexDirection="column" gap="md" alignItems={loadingResults ? "center" : null}>
                {loadingResults && (
                    <>
                        <Spinner sx={spinnerSX} size="xxl" color="primary" />
                        <Text>{loadingMessage}</Text>
                    </>
                )}
                {!loadingResults && (
                    <>
                        <Text>
                            <RichIcu icuContent={primaryMessage} />
                        </Text>

                        {rulesBrokenMessages && (
                            <>
                                <List variant="bullet" paddingBottom="0">
                                    {rulesBrokenMessages.map((rule, index) => (
                                        <ListItem key={index}>
                                            <Flex gap="xs-2">
                                                <RichIcu icuContent={rule} />
                                            </Flex>
                                        </ListItem>
                                    ))}
                                </List>
                                <Text>
                                    <RichIcu icuContent={secondaryMessage} />
                                </Text>
                            </>
                        )}
                    </>
                )}
            </Flex>
        </Flex>
    );
};
