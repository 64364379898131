// import type { LookupSearchMatchType } from "@costar-gen/pds-node-client";

import { Nullish } from "../../../types";

import { ProductId, SectionId } from "$common/nav-config";
import type { SearchItem } from "$graphql/core";

// /**
//  * @deprecated
//  * @todo remove and replace with type generated from graphql schema
//  */
// export type SearchHrefRedirect = {
//     href: string;
// };

// /**
//  * @deprecated
//  * @todo remove and replace with type generated from graphql schema
//  */
// export type SearchFormRedirect = {
//     action: string;
//     method: string;
//     formData: { [key: string]: string };
// };

export type SearchItemOption = SearchItem & BaseOption & { group?: JSX.Element; term?: string };
type BaseOption = {
    label: string;
    secondary?: string;
};
export type SearchTypeInfo = {
    /**
     * Placeholder text for pending searches.
     */
    placeholder: string;

    /**
     * Prompt text put into the search input field.
     */
    prompt: string;
    /**
     * URI (not full URL) to last search page.
     */
    lastURI: string;

    productId?: ProductId;
    subProductId?: SectionId | undefined;
};

/**
 * @todo remove in favor of graphql/core types generated from schema
 * @deprecated
 */
export enum SearchType {
    ALLPROPERTIES,
    FORLEASE,
    FORSALE,
    SALECOMPS,
    LEASECOMPS,
    NEWLEASECOMPS,
    PUBLICRECORD,
    TENANTS,
    MODERNTENANT,
    MODERNTENANTLOCATIONS,
    PROFESSIONALS,
    DIRECTORYCONTACTS,
    DIRECTORYLOCATIONS,
    DIRECTORYCOMPANIES,
    FUND,
    OWNERSCLOUD,
}

/**
 * @todo remove in favor of graphql/core types generated from schema
 * @deprecated
 */
export enum CountryCode {
    US,
    CA,
    GB,
}

/**
 * @todo remove in favor of graphql/core types generated from schema
 * @deprecated
 */

/**
 * @deprecated
 * @todo remove in favor of types generated from PDS
 * @see {@link import("@costar-gen/data-services-pds/types").CoStar.Api.Lookup.MatchType}
 */
export enum LookupSearchMatchType {
    Unknown = 0,
    Property = 1,
    PostalCode = 2,
    City = 3,
    BuildingName = 4,
    Submarket = 5,
    Street = 6,
    ResearchMarket = 7,
    Contact = 8,
    Company = 9,
    County = 10,
    Subdivision = 11,
    GeocodeResult = 12,
    BuildingPark = 13,
    ShoppingCenter = 14,
    Country = 15,
    PostalCodeGroup = 16,
    Region = 17,
    StoreType = 18,
    TransitStation = 19,
    PropertyResidential = 20,
    MultifamilyProperty = 21,
    Airport = 22,
    HotelName = 23,
    TransitStation2 = 24,
    Military = 25,
    University = 26,
    StoreBrand = 27,
    GoogleResults = 28,
    PropertyManagers = 29,
    SearchEngineOptimization = 30,
    Metro = 31,
    SaleCompID = 32,
    SubmarketCluster = 34,
    MSA = 35,
    PropertyGroup = 36,
    CompEntity = 50,
    Comp = 51,
    ForSale = 52,
    ContactSaleComp = 81,
    ContactShoppingCenter = 82,
    ContactLeaseDeal = 83,
    ContactTenant = 84,
    ContactProperty = 85,
    SubscriptionContact = 86,
    ContactLocation = 87,
    ContactCompany = 88,
    CompanySaleComp = 91,
    CompanyShoppingCenter = 92,
    CompanyLeaseDeal = 93,
    StreetIntersection = 131,
    CoStarMarket = 132,
    Fund = 137,
    FundOwner = 138,
    Tenant = 140,
    PostalCodeGlobal = 142,
}

/**
 * @todo remove in favor of type from generated pds node client
 * @deprecated
 */
// export enum GeographyFilterType {
//     Unknown = 0,
//     PostalCode = 2,
//     City = 3,
//     Submarket = 5,
//     Street = 6,
//     ResearchMarket = 7,
//     County = 10,
//     Subdivision = 11,
//     ShoppingCenter = 14,
//     Country = 15,
//     PostalCodeGroup = 16,
//     Region = 17,
//     CBSA = 18,
//     CSA = 19,
//     DMA = 20,
//     Cluster = 21,
//     Airport = 22,
//     HotelName = 23,
//     TransitStation = 24,
//     Military = 25,
//     University = 26,
//     SchoolWithAttendanceZone = 37,
//     SchoolWithNoAttendanceZone = 38,
//     SchoolDistrict = 39,
//     CityAggregate = 40,
//     CoStarMarket = 132,
// }
