import React, { FC, useMemo } from "react";

import subject from "@csgp-mfe-utils/event-observer";

import { Button, Tooltip } from "@costar/theme-ui";

import { MixedAnalyticsTooltip, MixedAnalyticsTooltipProps } from "./tooltip-content/mixed-analytics-tooltip";
import {
    SufficiencyCheckerTooltip,
    SufficiencyCheckerTooltipProps,
} from "./tooltip-content/sufficiency-checker-tooltip";

import { UUISubject, UUISubjectPayloads } from "$client/subjects";
import { ResultSetTotalCountTooltipConfig } from "$common/state/state";

interface TooltipRendererProps {
    tooltipMessage: string | ResultSetTotalCountTooltipConfig;
}

export const TooltipRenderer: FC<TooltipRendererProps> = props => {
    const { tooltipMessage } = props;

    const runSufficiencyChecker = () => {
        //when hovering over the tooltip, we will make a call to the Sufficiency Checker API
        subject<UUISubjectPayloads[UUISubject.ToolTipHover]>(UUISubject.ToolTipHover).notify();
    };

    const tooltipProps = useMemo(() => {
        if (typeof tooltipMessage === "string") {
            return { title: tooltipMessage };
        } else {
            const { tooltipComponentType, data } = tooltipMessage;
            //Add custom tooltip types here
            switch (tooltipComponentType) {
                case "MixedAnalyticsTooltip":
                    return {
                        title: <MixedAnalyticsTooltip {...(data as MixedAnalyticsTooltipProps)} />,
                        interactive: true,
                        maxWidth: 461,
                    };
                case "SufficiencyCheckerTooltip":
                    return {
                        title: <SufficiencyCheckerTooltip {...(data as SufficiencyCheckerTooltipProps)} />,
                        interactive: true,
                        maxWidth: 461,
                        onOpen: () => runSufficiencyChecker(),
                        onClose: () => {
                            return undefined;
                        }, //prevents api from being called onClose
                    };
                default:
                    return { title: "" };
            }
        }
    }, [tooltipMessage]);

    return (
        <Tooltip arrow {...tooltipProps}>
            <Button icon="info" variant="ghost" />
        </Tooltip>
    );
};
